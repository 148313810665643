import { Options } from '../types'
import { convertResultToLegacyFormat } from '../utils'
import { tenantService, apiService } from './apiService'
import { fetchFireMeetingTypeId } from './slugMappingApiService'

const REQUEST_TIMEOUT = 60000
const REQUEST_TIMEOUT_MESSAGE = 'API request timed out'

type PostData = {
  form: Record<string, string>
  options?: Partial<Options>
  event?: Record<string, string>
}

export const callRestApi = (
  domain: string,
  router: string,
  postData: PostData,
  onSuccess: (result: any) => void,
  onError: (err: Error | string) => void,
  isLegacyEnabled = true
) => {
  const conciergePromise = new Promise((resolve, reject) => {
    fetchFireMeetingTypeId(domain, postData.options?.type).then(fireMeetingTypeId => {
      tenantService(
        domain,
        // lowercase router slug before sending it cause slugs are all lowercase in fire
        `/concierge-router/${router.toLowerCase()}/rest?sourceUrl=${window.location.href}`,
        { ...postData, options: { ...(postData.options || {}), meetingTypeId: fireMeetingTypeId } }
      )
        .then(response =>
          resolve(convertResultToLegacyFormat(response, Boolean(postData.options?.domElement)))
        )
        .catch(err => {
          console.error('error', err)
          if (
            isLegacyEnabled &&
            (err.status === 412 || err.status === 421 || err.status === 404 || err.status === 403)
          ) {
            console.warn('chilipiper >> calling legacy, fire migration API result', err)
            apiService(domain, `/marketing/${domain}`, postData).then(resolve).catch(reject)
          } else {
            reject(err)
          }
        })
    })
  })
  const timeoutPromise = new Promise(resolve => {
    setTimeout(resolve, REQUEST_TIMEOUT, REQUEST_TIMEOUT_MESSAGE)
  })
  Promise.race([conciergePromise, timeoutPromise])
    .then(result => {
      if (result === REQUEST_TIMEOUT_MESSAGE) {
        onError(REQUEST_TIMEOUT_MESSAGE)
      } else {
        onSuccess(result)
      }
    })
    .catch(error => onError(error))
}
