import { getTenantService } from './apiService'

interface SlugMappingResponse {
  entityFireId?: string | null
  entityLegacyId?: string | null
  entityType: string
  fireSlug?: string
  legacySlug: string
}

export const fetchFireMeetingTypeId = (domain: string, legacyMeetingType?: string) => {
  if (!legacyMeetingType) {
    return Promise.resolve(undefined)
  }
  return getTenantService(
    domain,
    // lowercase router slug before sending it cause slugs are all lowercase in fire
    `/api/firerouter/v1/slug-mapping-by-legacy-by-subdomain/${domain}/adminSchedulingLink/${legacyMeetingType}`
  )
    .then((response: SlugMappingResponse) => response.entityFireId)
    .catch(err => {
      console.warn(
        'chilipiper >> could not find mapping for legacy meeting type to fire meeting type'
      )
      return undefined
    })
}
